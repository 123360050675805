html {
  overflow: hidden;
}

html,
body,
#root,
#viewsParentContainer,
.react-swipeable-view-container,
.rsvpPage {
  height: 100% !important;
  text-align: center;
}

body {
  /* background-image: url("../img/htmlback.jpg"); */
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', "Great Vibes",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  max-width: 464px;
  max-height: 850px;

  /* border: 10px solid silver; */
  border-radius: 15px;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


#preweddingvideo {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: calc(100% - 40px) !important;
  object-fit: fill;
  color: #fff;
}

#startPage {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 11100;
  color: #fff;
  top: 0;
  background-color: black;
  /* opacity: 0.7; */
  visibility: visible;
}

#startPageBtn {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  width: 180px;
  height: 180px;
  border: 3px solid #fff;
  border-radius: 90px;
  box-shadow: 0 0 20px 0px;
}

.fadeIn {
  animation: fadeIn 5s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

#rsvp {
  color: #fff;
  font-family: 'Lora', serif;
  visibility: hidden;
  background-color: #000;
  width: 100%;
  height: calc(100% - 40px);
  position: absolute;
  top: 0;
}

#rsvpHeader {
  height: 10%;
  font-family: 'Great Vibes', cursive;
  font-size: 40px;
}

#rsvpBody {
  height: 83%;
  font-family: "antialiased";
  font-size: 25px;
}


#rsvpFooter {
  position: relative;
  height: 6%;
  text-align: center;
}

#locationBtn {
  font-size: 30px;
  position: absolute;
  bottom: 15px;
  left: 15px;
  cursor: pointer;
}

#locationBtn a {
  font-size: 30px;
  color: black;
  text-decoration: none;
}

.attendingPart {
  width: 250px;
  margin: 0 auto;
  height: 40px;
  border: 2px solid #fff;
  background: rgba(72, 72, 72, 0.4);
  border-radius: 5px;
  color: #fff;
  font-size: 18px;
  padding: 0 15px;
  box-sizing: border-box;
  outline: none;
}

#leafContainer {
  width: 100%;
  height: 100vh;
  position: fixed;
  overflow: hidden;
  pointer-events: none;
  z-index: 175;
}

#leafContainer>div {
  position: absolute;
  width: 45px;
  height: 45px;
  -webkit-animation-iteration-count: infinite, infinite;
  -webkit-animation-direction: normal, normal;
  -webkit-animation-timing-function: linear, ease-in;
}

#leafContainer.floral_white>div,
#leafContainer.floral>div,
#leafContainer.floral>div img,
#leafContainer.floral_white>div img {

  width: auto;
  height: 17px;
}


#leafContainer>div>img {
  position: absolute;
  width: auto;
  height: 55px;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: linear;
  -webkit-transform-origin: 50% -100%;
}

#leafContainer.floral>div>img,
leafContainer.floral_white>div>img {
  height: 17px;
}

#leafContainer.hearts_red>div>img,
#leafContainer.hearts_pink>div>img {
  height: 45px;
}

#leafContainer.bigLeaf>div>img {
  height: 65px;
}

#leafContainer.baloons>div>img {
  height: auto;
  width: 35px;
}


@keyframes fade {
  0% {
    /* opacity: 1; */
  }

  85% {
    /* opacity: 1; */
  }

  90% {
    /* opacity: 0; */
  }
}

@keyframes drop {
  0% {
    transform: translate(0px, 50px);
  }

  100% {
    transform: translate(0px, -1950px);
  }
}

@keyframes clockwiseSpin {
  0% {
    transform: rotate(-50deg);
  }

  60% {
    transform: rotate(50deg);
  }

  80% {
    transform: rotate(80deg);
  }

  100% {
    transform: rotate(-40deg);
  }
}

@keyframes counterclockwiseSpinAndFlip {
  0% {
    transform: scale(-1, 1) rotate(80deg);
  }

  100% {
    transform: scale(-1, 1) rotate(-80deg);
  }
}

@keyframes clockwiseSpin_baloon {
  0% {
    transform: rotate(-20deg);
  }

  60% {
    transform: rotate(20deg);
  }

  80% {
    transform: rotate(30deg);
  }

  100% {
    transform: rotate(-20deg);
  }
}

@keyframes counterclockwiseSpinAndFlip_baloon {
  0% {
    transform: scale(-1, 1) rotate(25deg);
  }

  100% {
    transform: scale(-1, 1) rotate(-25deg);
  }
}


#locationBottom {
  position: absolute;
  bottom: 0;
  height: 40px;
  width: 100%;
  background-color: #000;
  /* z-index: 10000; */
  visibility: hidden;
  padding-top: 2px;
}

#locationBottom a {
  width: fit-content;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  margin-top: 10px;
  padding: 2px 10px;
  border-radius: 10px;
  background-color: #fff;
  height: 20px !important;
  color: black;
  text-decoration: none;
}